import {useState, useEffect} from 'react';
import {onSnapshot, collection, limit, query} from 'firebase/firestore';
import {db} from "../firebase";
import {toLocalDate} from "../utils/date.util";

/**
 * Messages
 * Created on 9/29/24
 */
const Messages = () => {
    const [messages, setMessages] = useState();

    useEffect(() => {
        const unsubscribe = onSnapshot(
            query(collection(db, 'transactions'), limit(5)
            ), (snapshot) => {
            console.log(snapshot)
            const newData = snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}));
            setMessages(newData);
        });

        return () => unsubscribe(); // Unsubscribe on component unmount
    }, []);

    if (!messages) {
        return (
            <h1>No messages</h1>
        )
    }

    return (
        <div className="mt-5 w-full md:mx-0 md:w-5/12 xl:w-4/12">
            <ul>
                {
                    messages.map((message, index) => (
                        <li
                            key={index}
                            className="flex flex-row items-center justify-between space-x-10 w-full border rounded-md p-4 shadow-sm mb-2"
                        >
                            <div>
                                <h2 className={"font-semibold"}>{message.data.object.metadata.name}</h2>
                                <p>{message.data.object.metadata.message}</p>
                                <small>{toLocalDate(message.created)}</small>
                            </div>
                            <strong className={"text-2xl"}>${message.data.object.amount_total / 100}</strong>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}

export default Messages;
