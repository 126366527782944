import './App.css';
import PaymentForm from './Components/PaymentForm';
import Messages from "./Components/Messages";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <PaymentForm/>
                <Messages />
                {/*<div className="scroll-down4 mt-4"></div>*/}
            </header>

            {/*<div className="text-center text-white container mx-auto mb-10 md:w-1/2 px-2">*/}
            {/*    <p>*/}
            {/*        I'm a struggling software engineer in Boston, where the rent is as outrageous as my subscription*/}
            {/*        list. My 1-bedroom, ocean-view loft (with gym, sauna, and pool, of course) is a mere $4K a month.*/}
            {/*        Throw in my new convertible at $1.3K/month, and I'm practically living paycheck to paycheck. It's*/}
            {/*        gotten so bad, I might have to fire the cleaning lady or—gasp—cancel my boat club membership!*/}
            {/*    </p>*/}
            {/*    <p>*/}
            {/*        So, here I am, reaching out. In these tough times, let's come together to help me maintain my*/}
            {/*        "barely scraping by" lifestyle and, who knows, maybe even upgrade it. <br/> I'll definitely think of you*/}
            {/*        when I hit the top 1%!*/}
            {/*    </p>*/}
            {/*</div>*/}
        </div>
    );
}

export default App;
