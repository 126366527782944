import {getFirestore} from 'firebase/firestore';
import {initializeApp} from "firebase/app";

export const firebaseConfig = {
    apiKey: "AIzaSyDx0X_MC8ix_RsSzG0_ThgDkjuNrLGyYJo",
    authDomain: "onepercent-me.firebaseapp.com",
    projectId: "onepercent-me",
    storageBucket: "onepercent-me.appspot.com",
    messagingSenderId: "1059054160428",
    appId: "1:1059054160428:web:e9356ab8f63cb94e5b1f89"
}

initializeApp(firebaseConfig);
const db = getFirestore();

export {
    db
}
